import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import obj from "../Utils/helpers";
import { isEmpty } from "lodash";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import useScroll from "./Scroll";
import { priceFormat } from "../Utils/constants";
import BackLayHome from "./BackLayHome";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
import secureLocalStorage from "react-secure-storage";
import { MdScoreboard } from "react-icons/md";
import SportAccordianInnerSlip from "./SportAccordianInnerSlip";
import Slider from "react-slick";
import moment from "moment";
const SportAccordianInner = ({
  showScore,
  index,
  keyTime,
  setActiveFav,
  activeFav,
  matchOdds,
  object,
  addToFavioute,
  setShowScore,
  scrollHeight,
  multiOddsPremium,
  type,
}) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    cssEase: "linear",
  };

  const navigate = useNavigate();
  const {
    setBetSlipObject,
    setShowBetDialog,
    showBetDialog,
    betSlipObject,
    resetBetPosition,
    resetBetSlipObject,
    setResetBetPosition,
    betPlace,
    setLimit,
  } = useContext(BetSlipContext);
  const {
    user,
    parly,
    setParlyBet,
    parlyBet,
    profileData,
    setMessage,
    message,
  } = useContext(AuthContext);
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [runner, setRunner] = useState([]);
  const [premiumDetail, setPremiumDetail] = useState([]);
  const [premiumDetail2, setPremiumDetail2] = useState({});
  const [localLimit, setLocalLimit] = useState({});
  useEffect(() => {
    if (!isEmpty(profileData)) {
      setLocalLimit(
        profileData?.matchSetting?.find((res) => {
          return res?.type == "betFaire" && res?.sportType == object?.gameType;
        })
      );
    }
  }, [profileData]);

  let betCheckObj = {
    4: "cricket",
    2: "tennis",
    1: "soccer",
    3: "Casino",
  };
  useEffect(() => {
    if (parly) {
      if (betCheckObj[multiOddsPremium[0]?.eventType] == "soccer") {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId && res?.marketName == "1x2"
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "Total"
            );
          }) || {};
        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        }
      } else if (betCheckObj[multiOddsPremium[0]?.eventType] == "tennis") {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName?.includes("Winner")
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "1x2 Total"
            );
          }) || {};
        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        }
      } else {
        let obj1 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName?.includes("Winner")
            );
          }) || {};
        if (!isEmpty(obj1)) {
          setPremiumDetail(obj1);
        }
        let obj2 =
          multiOddsPremium?.find((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              res?.marketName == "Total"
            );
          }) || {};

        if (!isEmpty(obj2)) {
          setPremiumDetail2(obj2);
        } else {
          let newObj = multiOddsPremium?.filter((res) => {
            return (
              res?.betfairEventId == object?.eventId &&
              !res?.marketName?.includes("Winner")
            );
          });
          if (newObj?.length > 0) {
            setPremiumDetail2(newObj[0]);
          }
        }
      }
    }
  }, [parly, multiOddsPremium, type]);

  const getBetPosition = async (item) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?marketId=${item?.marketId}&eventId=${item?.eventId}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        setRunner((current) =>
          response_users?.results?.betFair?.length > 0
            ? current.map((res) => {
                let obj = response_users?.results?.betFair?.find(
                  (item) => item?.selectionId == res?.SelectionId
                );
                return {
                  ...res,
                  position:
                    obj?.selectionId == res?.SelectionId ? obj?.position : 0,
                  newPosition: 0,
                };
              })
            : current?.map((res) => {
                return {
                  ...res,
                  position: 0,
                  newPosition: 0,
                };
              })
        );
      }
    }
  };

  const handelChange = (odds, type, item, object, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setScrollId(index);
        setLimit(localLimit);
        setShowBetDialog(true);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: item,
          betType: type,
          playType: "betFair",
          object: {
            ...item,
            ...odds,
            teamName: item?.RunnerName,
            eventId: object?.eventId,
            marketId: object?.marketId,
          },
        }));
      }
    } else {
      navigate("/login");
    }
  };

  const handelBetFairPositions = (slipObj) => {
    let selectionsTemp = [...runner];

    if (betSlipObject?.bidPrice > 0) {
      if (slipObj?.object?.ib) {
        let backProfit = Number(
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice)
        );
        let backLoss = Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              } else {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              }
            })
          );
        }
      } else {
        let backProfit = Number(betSlipObject?.bidPrice);
        let backLoss =
          Number(betSlipObject.odds - 1) * Number(betSlipObject?.bidPrice);
        if (Math.abs(selectionsTemp[0]?.position) > 0) {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: Number(rl.position) - backLoss,
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(rl.position) + backProfit,
                };
              }
            })
          );
        } else {
          setRunner(
            selectionsTemp?.map((rl) => {
              if (rl.SelectionId == slipObj?.object?.ri) {
                return {
                  ...rl,
                  newPosition: -Number(backLoss),
                };
              } else {
                return {
                  ...rl,
                  newPosition: Number(backProfit),
                };
              }
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      getBetPosition(object);
    } else if (betPlace?.isPlaced && betPlace?.type == "error") {
      setResetBetPosition(false);
      resetBetSlipObject();
      setRunner((prev) => {
        return prev.map((res) => {
          return { ...res, newPosition: 0 };
        });
      });
    }
  }, [betPlace]);

  useEffect(() => {
    if (resetBetPosition) {
      setResetBetPosition(false);
      resetBetSlipObject();
      setRunner((prev) => {
        return prev.map((res) => {
          return { ...res, newPosition: 0 };
        });
      });
    }
  }, [resetBetPosition]);

  useEffect(() => {
    if (showBetDialog) {
      if (!isEmpty(betSlipObject)) {
        if (betSlipObject?.playType == "betFair") {
          handelBetFairPositions(betSlipObject, runner);
        }
      }
    }
  }, [betSlipObject, showBetDialog]);

  useEffect(() => {
    setRunner(object?.runners);
    if (!isEmpty(user)) {
      getBetPosition(object);
    }
  }, [user]);

  const handelPaly = (item, res) => {
    setParlyBet((current) => {
      let newObj = {
        ...item,
        sportsBookSelectionObject: res,
        selectObj: res?.id,
        newEventId: object?.eventId,
        eventName: object?.eventName,
        marketId: object?.marketId,
      };
      if (current?.length > 0) {
        if (
          current?.filter((temp) => {
            return temp?.newEventId == object?.eventId;
          })?.length > 0
        ) {
          if (
            current?.filter((temp) => {
              return temp?.newEventId != object?.eventId;
            })?.length > 0
          ) {
            secureLocalStorage.setItem("parlyBet", [
              ...current?.filter((temp) => {
                return temp?.newEventId != object?.eventId;
              }),
              newObj,
            ]);
            return [
              ...current?.filter((temp) => {
                return temp?.newEventId != object?.eventId;
              }),
              newObj,
            ];
          } else {
            secureLocalStorage.setItem("parlyBet", [newObj]);
            return [newObj];
          }
        } else {
          secureLocalStorage.setItem("parlyBet", [...current, newObj]);
          return [...current, newObj];
        }
      } else {
        secureLocalStorage.setItem("parlyBet", [newObj]);
        return [newObj];
      }
    });
  };

  const isBeforeThreeHours = () => {
    const now = object?.eventDateTime;
    const threeHoursFromNow = moment(now).subtract(3, "hours").toDate();
    return moment(threeHoursFromNow).isAfter(moment().toDate());
  };
  let before3Hr = isBeforeThreeHours();
  return (
    <div className="sport-accord-body">
      <div className="position-relative">
        {parly && !isEmpty(premiumDetail) && (
          <div
            className="d-flex justify-content-between flex-column align-items-center"
            style={{
              background: "linear-gradient(180deg, #4d4d4d 0%, #363636 100%)",
              paddingLeft: "0",
              paddingRight: "0",
            }}
          >
            <div className="w-100 d-flex align-items-center position-relative">
              <div
                style={{
                  height: "23px",
                  background: "#ffb80c",
                  width: "50%",
                  borderTopLeftRadius: "4px",
                }}
                className="parly-section-header"
              ></div>
              <div
                style={{
                  height: "23px",
                  background: "#ffb80c",
                  width: "50%",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              ></div>
            </div>
            <div className="d-flex w-100 h-100">
              <div
                style={{
                  background: "linear-gradient(rgb(77, 77, 77) 0%, rgb(54, 54, 54) 100%)",
                  height: "auto",
                  color: "white",
                }}
                className="d-flex flex-column w-100 align-items-start p-2"
              >
                <span
                  onClick={() => {
                    navigate(
                      `/full-market/${object?.marketId}/${object?.eventId}`
                    );
                  }}
                >
                  {object?.eventName}
                  <br />{" "}
                </span>
              </div>{" "}
              <div
                style={{
                  background: "linear-gradient(180deg, #4d4d4d 0%, #363636 100%)",
                  marginTop: "-22.4px",
                  paddingBottom: "16px",
                  borderTopRightRadius: "4px",
                }}
                className="d-flex flex-column w-50 h-100 align-items-start"
              >
                <Slider className="w-100 h-100 parly-slider" {...settings}>
                  <div>
                    <div className="parly-list-page-outer">
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                        }}
                        className="d-flex justify-content-center"
                      >
                        {object?.gameType == "soccer" ? "1x2" : "Winner"}
                      </span>{" "}
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                          // paddingTop: "10px",
                        }}
                        className="d-flex justify-content-center"
                      >
                        O/U
                      </span>
                    </div>

                    <div className="parly-list-page">
                      <div
                        className={
                          !isEmpty(premiumDetail) &&
                          premiumDetail?.marketName?.includes("1x2")
                            ? "parly-list-page-inner parly-soccer"
                            : "parly-list-page-inner"
                        }
                      >
                        {!isEmpty(premiumDetail) &&
                        premiumDetail?.apiSiteStatus !== "ACTIVE" ? (
                          <span className="suspended">Suspended</span>
                        ) : premiumDetail?.marketStatus == 9 ||
                          premiumDetail?.marketStatus == 2 ||
                          premiumDetail?.marketStatus == 3 ||
                          premiumDetail?.marketStatus == 4 ? (
                          <span className="suspended">
                            {premiumDetail?.marketStatus == 9
                              ? "Ball Running"
                              : premiumDetail?.marketStatus == 2
                              ? "In Active"
                              : premiumDetail?.marketStatus == 3
                              ? "Suspended"
                              : premiumDetail?.marketStatus == 4
                              ? "Closed"
                              : "Suspended"}
                          </span>
                        ) : (
                          ""
                        )}
                        {premiumDetail?.sportsBookSelection?.length > 0 ? (
                          <>
                            {premiumDetail?.sportsBookSelection
                              ?.slice(0, 3)
                              ?.map((res) => {
                                let obj = parlyBet?.find((item) => {
                                  return (
                                    item?.marketName ==
                                      premiumDetail?.marketName &&
                                    item?.sportsBookSelectionObject?.odds ==
                                      res?.odds
                                  );
                                });
                                let oldObject =
                                  premiumDetail?.oldSportsBookSelection?.find(
                                    (obj) => {
                                      return obj?.id == res?.id;
                                    }
                                  ) || {};
                                return (
                                  <SportAccordianInnerSlip
                                    premiumDetail={premiumDetail}
                                    handelPaly={handelPaly}
                                    obj={obj}
                                    res={res}
                                    oldObject={oldObject}
                                  />
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                          </>
                        )}{" "}
                      </div>
                      <div
                        className={
                          !isEmpty(premiumDetail2) &&
                          premiumDetail2?.marketName?.includes("1x2")
                            ? "parly-list-page-inner parly-soccer"
                            : "parly-list-page-inner"
                        }
                      >
                        {!isEmpty(premiumDetail2) &&
                        premiumDetail2?.apiSiteStatus !== "ACTIVE" ? (
                          <span className="suspended">Suspended</span>
                        ) : premiumDetail2?.marketStatus == 9 ||
                          premiumDetail2?.marketStatus == 2 ||
                          premiumDetail2?.marketStatus == 3 ||
                          premiumDetail2?.marketStatus == 4 ? (
                          <span className="suspended">
                            {premiumDetail2?.marketStatus == 9
                              ? "Ball Running"
                              : premiumDetail2?.marketStatus == 2
                              ? "In Active"
                              : premiumDetail2?.marketStatus == 3
                              ? "Suspended"
                              : premiumDetail2?.marketStatus == 4
                              ? "Closed"
                              : "Suspended"}
                          </span>
                        ) : (
                          ""
                        )}
                        {premiumDetail2?.sportsBookSelection?.length > 0 ? (
                          <>
                            {premiumDetail2?.sportsBookSelection
                              ?.slice(0, 3)
                              ?.map((res) => {
                                let obj = parlyBet?.find((item) => {
                                  return (
                                    item?.marketName ==
                                      premiumDetail2?.marketName &&
                                    item?.sportsBookSelectionObject?.odds ==
                                      res?.odds
                                  );
                                });
                                let oldObject =
                                  premiumDetail2?.oldSportsBookSelection?.find(
                                    (obj) => {
                                      return obj?.id == res?.id;
                                    }
                                  ) || {};
                                return (
                                  <SportAccordianInnerSlip
                                    premiumDetail={premiumDetail2}
                                    handelPaly={handelPaly}
                                    obj={obj}
                                    res={res}
                                    oldObject={oldObject}
                                  />
                                );
                              })}
                          </>
                        ) : (
                          <>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                            <div>
                              <span></span>
                              <strong></strong>
                            </div>
                          </>
                        )}{" "}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="parly-list-page-outer">
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                        }}
                        className="d-flex justify-content-center"
                      >
                        Away Inn O/U
                      </span>{" "}
                      <span
                        style={{
                          fontSize: ".7142857142857143rem",
                          width: "50%",
                        }}
                        className="d-flex justify-content-center"
                      >
                        Total 6 O/U
                      </span>
                    </div>
                    <div className="parly-list-page">
                      <>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                      </>
                      <>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                        <div>
                          <span></span>
                          <strong></strong>
                        </div>
                      </>
                      {/* // )} */}
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        )}

        {runner?.length > 0 &&
          !isEmpty(matchOdds) &&
          !parly &&
          object?.marketId == matchOdds?.mi && (
            <div className="sports-team-table">
              <div className="match-odd-table bg-white p-3 px-0 py-1">
                <div className="d-flex back-lay-head justify-content-end ms-auto pb-1 bg-yellow w-100">
                  <div
                    className="half-table"
                    style={{
                      display: "flex",
                      flex: "1",
                      justifyContent: "start",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <h4 className="text-left">Matched</h4>
                    <strong className="px-2">
                      {matchOdds?.totalMatched > 0 &&
                        `${priceFormat(matchOdds?.totalMatched)}`}
                    </strong>
                    {matchOdds?.totalMatched < 10000 && (
                      <strong className="bg-danger rounded text-white low-button">
                        Low
                      </strong>
                    )}
                  </div>
                  <span style={{ width: "71px" }} className="text-center">
                    Back
                  </span>
                  <span style={{ width: "71px" }} className="text-center">
                    Lay
                  </span>
                </div>
                <ul className="mb-2 rounded position-relative p-0">
                  {runner?.map((newObj) => {
                    const odds_back = matchOdds?.rt
                      ?.filter((rt) => {
                        return rt.ib;
                      })
                      ?.filter((todd) => todd.ri == newObj.SelectionId);
                    const odds_lay = matchOdds?.rt
                      ?.filter((rt) => {
                        return !rt.ib;
                      })
                      ?.filter((todd) => todd.ri == newObj.SelectionId);
                    const odds_lay2 =
                      odds_lay?.find(
                        (todd) => todd.ri === newObj.SelectionId
                      ) || {};
                    let odds_back2 =
                      odds_back?.find(
                        (todd) => todd.ri === newObj.SelectionId
                      ) || {};
                    let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
                    let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
                    let disable = false;
                    if (odds_lay?.length == 0 && odds_back?.length == 0) {
                      disable = true;
                    } else if (layRt <= 0 && backRt <= 0) {
                      disable = true;
                    } else if (
                      (layRt > localLimit?.oddsLimit || layRt == 0) &&
                      (layRt > localLimit?.oddsLimit || backRt == 0)
                    ) {
                      disable = true;
                    } else {
                      disable = false;
                    }
                    return (
                      <li
                        className="position-relative"
                        style={{ scrollMarginTop: scrollHeight }}
                        ref={index == scrollId ? myRef : null}
                        onClick={() =>
                          navigate(
                            `/full-market/${object?.marketId}/${object?.eventId}`
                          )
                        }
                      >
                        <h2>
                          {newObj?.RunnerName}{" "}
                          {(Math?.abs(newObj?.position) > 0 ||
                            Math.abs(newObj?.newPosition) > 0) && (
                            <span
                              className={`d-block py-1 ${
                                newObj?.position > 0
                                  ? "text-green team-red-score"
                                  : "team-red-score"
                              }`}
                            >
                              {Math?.abs(newObj?.position) > 0
                                ? newObj?.position > 0
                                  ? Math?.abs(newObj?.position).toFixed(2)
                                  : `( ${Math.abs(newObj?.position).toFixed(
                                      2
                                    )} )`
                                : ""}
                              {object?.eventId ==
                                betSlipObject?.object?.eventId &&
                                Math.abs(newObj?.newPosition) > 0 && (
                                  <span
                                    className={
                                      newObj?.newPosition > 0
                                        ? "text-green"
                                        : ""
                                    }
                                  >
                                    {" "}
                                    {">"}{" "}
                                    {newObj?.newPosition > 0
                                      ? Math.abs(newObj?.newPosition).toFixed(2)
                                      : `( ${Math.abs(
                                          newObj?.newPosition
                                        ).toFixed(2)} )`}
                                  </span>
                                )}
                            </span>
                          )}
                        </h2>
                        <BackLayHome
                          component={"betfair"}
                          type="sport"
                          index={index}
                          item={newObj}
                          object={object}
                          handelChange={handelChange}
                          odds_lay={odds_lay}
                          odds_back={odds_back}
                          ms={matchOdds?.ms}
                        />
                        {disable && matchOdds?.ms == 1 && (
                          <div className="overlay-match">Suspended</div>
                        )}
                      </li>
                    );
                  })}
                  {(before3Hr || matchOdds?.ms != 1) && (
                    <div className="overlay-match">Suspended</div>
                  )}
                </ul>
              </div>
            </div>
          )}
        {showScore?.includes(object?.scoreId) && (
          <LiveMatchTracker
            scoreId={object?.scoreId}
            st={true}
            game={object?.gameType}
          />
        )}
      </div>
    </div>
  );
};

export default SportAccordianInner;

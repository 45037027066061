import React, { useContext, useEffect, useState } from "react";
import LayoutNew from "../components/shared/LayoutNew";
import MainBanner from "../pages/slider/MainBanner";
import Casino from "../pages/casino-home/Casino";

import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import jwt_decode from "jwt-decode";
import moment from "moment";
import helpers from "../Utils/helpers";
import NoEvent from "../components/NoEvent";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import { FaSearch, FaBell, FaCog, FaTv, FaAngleLeft } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CurrentNews from "../components/CurrentNews";
import { io } from "socket.io-client";
import HomeInner from "../components/HomeInner";
const Home = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
    messagelist,
    setAnnouncement,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showScore, setShowScore] = useState([]);
  const [sportsData, setSportsData] = useState([]);
  const [type, setType] = useState("inplay");
  const [ttype, setttType] = useState("inplay");
  const [gameType, setGameType] = useState("");
  const [list, setList] = useState([]);
  const [updatedList, setUpdatedList] = useState([]);
  const [selected, setSelected] = useState({});
  const [inplayCountData, setInplayCountData] = useState({});
  const [sketon, setSketon] = useState(false);
  const [newResult, setNewResult] = useState([]);
  const [show, setShow] = useState(false);
  const [activeKeys, setActiveKeys] = useState([0]);
  const [activeFav, setActiveFav] = useState([]);
  const [odds, setOdds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [eventIdPremium, setEventIdPremium] = useState([]);
  const [cricketScore, setCricketScore] = useState([]);
  const [updatedObj, setUpdatedObj] = useState({});
  // Socket
  const [checkRefresh, setCheckRefresh] = useState(true);
  const [socketObj, setSocketObj] = useState(null);
  const [score, setScore] = useState([]);
  const scoreCricketAll = (e) => {
    setCricketScore(e);
  };
  const fetchAllSport = async (obj) => {
    setSketon(true);
    let temp = !isEmpty(user) ? `&userId=${user.user._id}` : "";
    let parlyType = parly ? `&pagetype=multi` : "";
    const { status, data: response_users } = await apiGet(
      apiPath.userInPlaySport +
        `?type=${!isEmpty(obj) ? obj : type}` +
        temp +
        parlyType
    );

    if (status === 200) {
      if (response_users.success) {
        let newResult = response_users?.results[type];
        setNewResult(newResult);
        setEventIdPremium(
          newResult?.map((res) => {
            return res?.eventId;
          })
        );
        if (gameType !== "") {
          setSportsData(
            response_users?.results[type]?.filter(
              (res) => res?.gameType == gameType
            )
          );
        } else {
          setSportsData(response_users?.results[type]);
        }

        setSketon(false);
      }
    }
  };

  const leagueData = async () => {
    const { status, data } = await apiGet(
      apiPath.seriesList + "?domain=magicplay247.com.live"
    );
    if (data?.success) {
      setList(data?.results);
      setUpdatedList(() => {
        return filteredArray(
          data?.results?.filter((res) => {
            return res?.gameType == gameType;
          })
        );
      });
    }
  };
  const getData = async () => {
    const { status, data } = await apiGet(apiPath.seriesList, {
      gameType: gameType,
      type: type == "inplay" ? "in_play" : type == "tomorrow" ? "active" : "",
      userId: !isEmpty(localStorage.getItem("token"))
        ? jwt_decode(localStorage.getItem("token"))?.user?._id
        : "",
    });
    if (data?.success) {
      let newResult = data?.results
        ?.map((res) => {
          return res?.matchList;
        })
        .flat();
      setNewResult(newResult);
      setEventIdPremium(
        newResult?.map((res) => {
          return res?.eventId;
        })
      );
      let temp = data?.results
        ?.filter((res) => {
          return res?.hasMatchOddsInList;
        })
        ?.map((res) => {
          return res?.seriesId;
        });
      setActiveKeys(temp);
      setActiveFav(
        data?.results
          ?.map((res) => {
            return res?.matchList;
          })
          .flat()
          ?.filter((res) => {
            return res?.isFavourite;
          })
          ?.map((res) => {
            return res?.eventId;
          })
      );
      let newArray = data?.results
        ?.map((res) => {
          return res?.matchList;
        })
        ?.flat();
      if (type == "inplay") {
        let obj = {
          cricket:
            filteredArray(
              newArray?.filter((res) => res?.gameType == "cricket")
            ) || [],
          tennis:
            filteredArray(
              newArray?.filter((res) => res?.gameType == "tennis")
            ) || [],
          soccer:
            filteredArray(
              newArray?.filter((res) => res?.gameType == "soccer")
            ) || [],
        };
        setUpdatedObj(obj);
      } else {
        setUpdatedList(newArray);
        setList(newArray);
      }
      setLoader(false);
    }
  };

  const filteredArray = (array) => {
    if (array?.length > 0) {
      let check = array?.filter((res) => {
        return res?.seriesName == "Others";
      });
      if (check?.length > 1) {
        let find = check?.find((res) => {
          return res?.seriesName == "Others";
        });
        return [
          ...array?.filter((res) => {
            return res?.seriesName !== "Others";
          }),
          {
            ...find,
            matchList: check
              ?.map((res) => {
                return res?.matchList;
              })
              .flat(),
          },
        ];
      } else {
        return array;
      }
    }
  };

  useEffect(() => {
    if (type == "leagues") {
      leagueData();
    } else {
      setList([]);
      getData();
    }
  }, [type, gameType]);

  const inPlayCount = async () => {
    const { status, data: response_users } = await apiGet(apiPath.inPlayCount);
    if (status === 200) {
      if (response_users.success) {
        if (response_users.results) {
          setInplayCountData(response_users.results);
        }
      }
    }
  };
  useEffect(() => {
    inPlayCount();
  }, []);

  const getMatchOddsMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOdds +
        `?marketId=${id
          ?.map((res) => {
            return res?.marketId;
          })
          ?.join(",")}&multi=true&eventIds=${id
          ?.map((res) => {
            return res?.eventId;
          })
          ?.join(",")}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };

  useEffect(() => {
    if (newResult?.length > 0 && !parly) {
      getMatchOddsMulti(newResult);
    }
  }, [newResult]);

  const [multiOddsPremium, setMultiOddsPremium] = useState([]);
  const getPremiumMulti = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy + `?events=${id?.join(",")}&parly=true&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setMultiOddsPremium(socket_data);
      }
    }
  };

  useEffect(() => {
    if (eventIdPremium?.length > 0 && parly) {
      getPremiumMulti(eventIdPremium);
    }
  }, [eventIdPremium, parly]);

  useEffect(() => {
    if (eventIdPremium?.length > 0) {
      let interval = setInterval(() => {
        if (parly) {
          getPremiumMulti(eventIdPremium);
        }
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [eventIdPremium, parly]);

  const getScore = (message) => {
    setScore(message);
  };
  const getSocket = () => {
    if (checkRefresh) {
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
      setCheckRefresh(false);
      let newSocket = "";
      let randomId = user?.user?._id ? user?.user?._id : 112233;
      if (!isEmpty(user)) {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?token=${randomId}&userType=front`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      } else {
        newSocket = io(
          `${process.env.REACT_APP_API_SCORE_URL}?user_id=${randomId}`,
          {
            transports: ["websocket"],
            forceNew: true,
          }
        );
      }
      setSocketObj(newSocket);
      newSocket.on("scoreAll", getScore);
      newSocket.on("scoreCricketAll", scoreCricketAll);
      newSocket.on("disconnect", function () {
        setCheckRefresh(true);
      });
      return () => newSocket.close();
    }
  };

  useEffect(() => {
    if (checkRefresh) {
      getSocket();
    }
  }, [checkRefresh]);

  document.addEventListener("visibilitychange", function () {
    if (!document.hidden && !checkRefresh) {
      setCheckRefresh(true);
      if (!isEmpty(socketObj)) {
        socketObj.disconnect();
      }
    } else {
      setCheckRefresh(false);
    }
  });

  useEffect(() => {
    if (newResult?.length > 0) {
      let interval = setInterval(() => {
        if (!parly) {
          getMatchOddsMulti(newResult);
        }
      }, 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [newResult, parly]);

  const handelShowScore = (e) => {
    if (showScore?.includes(e)) {
      setShowScore((prev) => {
        return prev.filter((item) => {
          return item != e;
        });
      });
    } else {
      if (e !== "0") {
        setShowScore((prev) => {
          return [...prev, e];
        });
      }
    }
  };
  return (
    <LayoutNew>
      {searchTab ? (
        <SearchBar />
      ) : (
        <>
          <MainBanner />
          <CurrentNews
            message={messagelist}
            setAnnouncement={setAnnouncement}
          />
          <div className="d-div">
            <div
            // style="height: 50px; display: block;"
            ></div>
            <div class="ftype d-flex justify-content-between">
              <ul>
                <li
                  className={type == "inplay" ? "active" : ""}
                  onClick={() => {
                    setType("inplay");
                    setParly(false);
                    setGameType("");
                  }}
                >
                  <span class="left-view">
                    <svg
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                      _mstVisible="6"
                    >
                      <title _mstHash="188" _mstVisible="7">
                        tab-radius-l
                      </title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="true"
                        class="tab-bg"
                        _mstVisible="7"
                      >
                        <path
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-l"
                          transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                          _mstVisible="8"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  Live{" "}
                  <span class="right-view">
                    <svg
                      data-v-16f79fce=""
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                    >
                      <title data-v-16f79fce="">tab-radius-r</title>
                      <g
                        data-v-16f79fce=""
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="false"
                        class="tab-bg"
                      >
                        <path
                          data-v-16f79fce=""
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-r"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
                <li
                  className={type == "tomorrow" ? "active" : ""}
                  onClick={() => {
                    setType("tomorrow");
                    setGameType("cricket");
                    setParly(false);
                  }}
                >
                  <span class="left-view">
                    <svg
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                      _mstVisible="6"
                    >
                      <title _mstHash="188" _mstVisible="7">
                        tab-radius-l
                      </title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="true"
                        class="tab-bg"
                        _mstVisible="7"
                      >
                        <path
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-l"
                          transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                          _mstVisible="8"
                        ></path>
                      </g>
                    </svg>
                  </span>{" "}
                  Upcoming
                  <span class="right-view">
                    <svg
                      data-v-16f79fce=""
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                    >
                      <title data-v-16f79fce="">tab-radius-r</title>
                      <g
                        data-v-16f79fce=""
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="false"
                        class="tab-bg"
                      >
                        <path
                          data-v-16f79fce=""
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-r"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
                {!parly && (
                  <li
                    className={type == "leagues" ? "active" : ""}
                    onClick={() => {
                      setType("leagues");
                      setParly(false);
                    }}
                  >
                    <span class="left-view">
                      <svg
                        width="8px"
                        height="8px"
                        viewBox="0 0 8 8"
                        version="1.1"
                        class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                        _mstVisible="6"
                      >
                        <title _mstHash="188" _mstVisible="7">
                          tab-radius-l
                        </title>
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          data-is-selected="true"
                          class="tab-bg"
                          _mstVisible="7"
                        >
                          <path
                            d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                            id="tab-radius-l"
                            transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                            _mstVisible="8"
                          ></path>
                        </g>
                      </svg>
                    </span>{" "}
                    Leagues{" "}
                    <span class="right-view">
                      <svg
                        data-v-16f79fce=""
                        width="8px"
                        height="8px"
                        viewBox="0 0 8 8"
                        version="1.1"
                        class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                      >
                        <title data-v-16f79fce="">tab-radius-r</title>
                        <g
                          data-v-16f79fce=""
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          data-is-selected="false"
                          class="tab-bg"
                        >
                          <path
                            data-v-16f79fce=""
                            d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                            id="tab-radius-r"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </li>
                )}

                <li
                  onClick={() => {
                    if (isEmpty(user)) {
                      navigate("/login");
                    } else {
                      setParly(true);
                      setType("parlay");
                      setGameType("cricket");
                    }
                  }}
                  className={parly ? "parlyatab active" : "parlyatab"}
                >
                  <span class="left-view">
                    <svg
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-right-0 s-bottom-0 s-w-7px s-h-7px"
                      _mstVisible="6"
                    >
                      <title _mstHash="188" _mstVisible="7">
                        tab-radius-l
                      </title>
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="true"
                        class="tab-bg"
                        _mstVisible="7"
                      >
                        <path
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-l"
                          transform="translate(4.000000, 4.000000) scale(-1, 1) translate(-4.000000, -4.000000) "
                          _mstVisible="8"
                        ></path>
                      </g>
                    </svg>
                  </span>{" "}
                  Parlay
                  <span class="right-view">
                    <svg
                      data-v-16f79fce=""
                      width="8px"
                      height="8px"
                      viewBox="0 0 8 8"
                      version="1.1"
                      class="s-svg-image s-absolute s-left-0 s-bottom-0 s-w-7px s-h-7px"
                    >
                      <title data-v-16f79fce="">tab-radius-r</title>
                      <g
                        data-v-16f79fce=""
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        data-is-selected="false"
                        class="tab-bg"
                      >
                        <path
                          data-v-16f79fce=""
                          d="M0,0 C0,4.418278 3.581722,8 8,8 L0,8 L0,0 Z"
                          id="tab-radius-r"
                        ></path>
                      </g>
                    </svg>
                  </span>
                </li>
              </ul>
              <div className="settingtab d-flex">
                <span>
                  <FaSearch
                    onClick={() => setSearchTab(true)}
                    className="ms-auto"
                  />
                </span>
                <span>
                  <FaBell className="ms-auto" />
                </span>
                <span>
                  <FaCog className="ms-auto" />
                </span>
              </div>
            </div>
            <div class="list-s">
              {type == "inplay" && (
                <div
                  className={gameType == "" ? "list-box active" : "list-box"}
                  onClick={() => {
                    setType("inplay");
                    setGameType("");
                  }}
                >
                  <a href="#_">
                    <div class="tag-live">
                      <strong>all</strong>
                      {inplayCountData?.cricketInplayCount +
                        inplayCountData?.soccerInplayCount +
                        inplayCountData?.tennisInplayCount}
                    </div>
                    <div class="icon-holder-big">
                      <div class="live-icon"></div>
                    </div>
                    <div class="list-box-name">In-Play</div>
                  </a>
                </div>
              )}
              <div
                className={"list-box"}
                onClick={() => {
                  setGameType("casino");
                }}
              >
                <a href="javascript:void(0)">
                  <div class="icon-holder-big">
                    <div class="sports-icon casino"></div>
                  </div>
                  <div class="list-box-name">Casino</div>
                </a>
              </div>
              <div
                className={
                  gameType == "cricket" ? "list-box active" : "list-box"
                }
                onClick={() => {
                  setGameType("cricket");
                }}
              >
                <a href="#_">
                  <div class="tag-live">
                    <strong>34</strong>{" "}
                    {inplayCountData?.cricketInplayCount || 0}
                  </div>
                  <div class="icon-holder-big">
                    <div class="sports-icon cricket"></div>
                  </div>
                  <div class="list-box-name">Cricket</div>
                </a>
              </div>
              <div
                className={
                  gameType == "soccer" ? "list-box active" : "list-box"
                }
                onClick={() => {
                  setGameType("soccer");
                }}
              >
                <a href="#_">
                  <div class="tag-live">
                    <strong>50</strong>{" "}
                    {inplayCountData?.soccerInplayCount || 0}
                  </div>
                  <div class="icon-holder-big">
                    <div class="sports-icon soccer"></div>
                  </div>
                  <div class="list-box-name">Soccer</div>
                </a>
              </div>
              <div
                className={
                  gameType == "tennis" ? "list-box active" : "list-box"
                }
                onClick={() => {
                  setGameType("tennis");
                }}
              >
                <a href="#_">
                  <div class="tag-live">
                    <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                  </div>
                  <div class="icon-holder-big">
                    <div class="sports-icon tennis"></div>
                  </div>
                  <div class="list-box-name">Tennis</div>
                </a>
              </div>

              <div
                className={
                  gameType == "icehockey" ? "list-box active" : "list-box"
                }
                onClick={() => {
                  setGameType("icehockey");
                }}
              >
                <a href="#_">
                  <div class="tag-live">
                    <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                  </div>
                  <div class="icon-holder-big">
                    <div class="sports-icon ice-hockey"></div>
                  </div>
                  <div class="list-box-name">Ice Hockey</div>
                </a>
              </div>
              <div
                className={
                  gameType == "motorsport" ? "list-box active" : "list-box"
                }
                onClick={() => {
                  setGameType("motorsport");
                }}
              >
                <a href="#_">
                  <div class="tag-live">
                    <strong>8</strong> {inplayCountData?.tennisInplayCount || 0}
                  </div>
                  <div class="icon-holder-big">
                    <div class="sports-icon motor-sport"></div>
                  </div>
                  <div class="list-box-name">Motor Sport</div>
                </a>
              </div>
            </div>
            {gameType == "casino" && <Casino homclass={"home-class"} />}
            {type !== "leagues" ? (
              <>
                {!sketon ? (
                  <div class="data-t">
                    {type == "inplay" ? (
                      <>
                        {updatedObj?.cricket?.length > 0 && (
                          <h3 className="sports-heading">
                            <div class="icon-holder-big">
                              <div class="sports-icon cricket"></div>
                            </div>
                            <div className="h-game">
                              Cricket ({updatedObj?.cricket?.length})
                            </div>
                          </h3>
                        )}

                        {updatedObj?.cricket?.map((item, index) => {
                          let obj = moment.duration(
                            moment(item?.eventDateTime).diff(moment(new Date()))
                          )?._data;
                          let obj1 = helpers
                            .matchDateTime(item.eventDateTime)
                            .split(" ");
                          let matchOdds =
                            odds?.find((res) => {
                              return res?.mi == item?.marketId;
                            }) || {};
                          return (
                            <HomeInner
                              obj1={obj1}
                              obj={obj}
                              item={item}
                              matchOdds={matchOdds}
                              multiOddsPremium={multiOddsPremium}
                              setActiveFav={setActiveFav}
                              activeFav={activeFav}
                              score={[...score, ...cricketScore]}
                              showScore={showScore}
                              setShowScore={handelShowScore}
                              index={index}
                            />
                          );
                        })}
                        {updatedObj?.soccer?.length > 0 && (
                          <h3 className="sports-heading">
                            <div class="icon-holder-big">
                              <div class="sports-icon soccer"></div>
                            </div>
                            <div className="h-game">
                              Soccer ({updatedObj?.soccer?.length})
                            </div>
                          </h3>
                        )}
                        {updatedObj?.soccer?.map((item, index) => {
                          let obj = moment.duration(
                            moment(item?.eventDateTime).diff(moment(new Date()))
                          )?._data;
                          let obj1 = helpers
                            .matchDateTime(item.eventDateTime)
                            .split(" ");
                          let matchOdds =
                            odds?.find((res) => {
                              return res?.mi == item?.marketId;
                            }) || {};
                          return (
                            <HomeInner
                              obj1={obj1}
                              obj={obj}
                              item={item}
                              matchOdds={matchOdds}
                              multiOddsPremium={multiOddsPremium}
                              setActiveFav={setActiveFav}
                              activeFav={activeFav}
                              score={[...score, ...cricketScore]}
                              showScore={showScore}
                              setShowScore={handelShowScore}
                              index={index}
                            />
                          );
                        })}
                        {updatedObj?.tennis?.length > 0 && (
                          <h3 className="sports-heading">
                            <div class="icon-holder-big">
                              <div class="sports-icon tennis"></div>
                            </div>
                            <div className="h-game">
                              Tennis ({updatedObj?.tennis?.length})
                            </div>
                          </h3>
                        )}
                        {updatedObj?.tennis?.map((item, index) => {
                          let obj = moment.duration(
                            moment(item?.eventDateTime).diff(moment(new Date()))
                          )?._data;
                          let obj1 = helpers
                            .matchDateTime(item.eventDateTime)
                            .split(" ");
                          let matchOdds =
                            odds?.find((res) => {
                              return res?.mi == item?.marketId;
                            }) || {};
                          return (
                            <HomeInner
                              obj1={obj1}
                              obj={obj}
                              item={item}
                              matchOdds={matchOdds}
                              multiOddsPremium={multiOddsPremium}
                              setActiveFav={setActiveFav}
                              activeFav={activeFav}
                              score={[...score, ...cricketScore]}
                              showScore={showScore}
                              setShowScore={handelShowScore}
                              index={index}
                            />
                          );
                        })}
                      </>
                    ) : (
                      list.length > 0 &&
                      list.map((item, index) => {
                        let obj = moment.duration(
                          moment(item?.eventDateTime).diff(moment(new Date()))
                        )?._data;

                        let obj1 = helpers
                          .matchDateTime(item.eventDateTime)
                          .split(" ");
                        let matchOdds =
                          odds?.find((res) => {
                            return res?.mi == item?.marketId;
                          }) || {};
                        return (
                          <HomeInner
                            obj1={obj1}
                            obj={obj}
                            item={item}
                            matchOdds={matchOdds}
                            multiOddsPremium={multiOddsPremium}
                            setActiveFav={setActiveFav}
                            activeFav={activeFav}
                            score={[...score, ...cricketScore]}
                            showScore={showScore}
                            setShowScore={handelShowScore}
                            index={index}
                          />
                        );
                      })
                    )}
                  </div>
                ) : (
                  <div className="sketonc">
                    <Skeleton
                      count={20}
                      baseColor="#303030"
                      highlightColor="#7e8081"
                    />{" "}
                  </div>
                )}
              </>
            ) : (
              isEmpty(selected) && (
                <div>
                  {updatedList?.length > 0 ? (
                    <div className="d-flex p-3 leage-list main-casino-wrapper">
                      {/* <aside className="text-center">
                  <ul className="p-0 mb-0">
                    <li>C</li>
                    <li>I</li>
                    <li>O</li>
                  </ul>
                </aside> */}
                      <div
                        className={`main-list w-100 ${
                          updatedList?.length > 7 ? "mb-5" : ""
                        }`}
                      >
                        <h6 className="mb-3">Popular</h6>
                        {updatedList?.map((item, index) => {
                          return (
                            <div className="w-100 mb-1 match-list">
                              <ul className="p-0 m-0">
                                <li onClick={() => setSelected(item)}>
                                  {item?.seriesName}
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex p-3 justify-content-center align-items-center leage-list main-casino-wrapper">
                      <NoEvent />
                    </div>
                  )}
                </div>
              )
            )}

            {/* second-level */}
            {!isEmpty(selected) && (
              <div className="second-level-leage">
                <div className="balance-label position-relative text-start ps-5">
                  <button
                    onClick={() => setSelected({})}
                    className="bg-transparent border-0 text-white position-absolute start-0 pt-0"
                  >
                    <FaAngleLeft className="fs-3" />
                  </button>
                  {selected?.seriesName}
                </div>

                <div className="d-flex p-3 px-4 leage-list main-casino-wrapper">
                  <div
                    className={`main-list w-100 ${
                      selected?.matchList?.length > 7 ? "mb-5" : ""
                    }`}
                  >
                    {selected?.matchList?.map((item, index) => {
                      return (
                        <div className="w-100 mb-3 match-list">
                          <ul className="p-0 m-0">
                            <li
                              onClick={() =>
                                navigate(
                                  `/full-market/${item?.marketId}/${item?.eventId}`
                                )
                              }
                            >
                              {item?.runners?.map((res) => {
                                return (
                                  <span className="d-block">
                                    {res?.RunnerName}
                                  </span>
                                );
                              })}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </LayoutNew>
  );
};

export default Home;

import React from "react";
import { FaTv } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SportAccordianInner from "./SportAccordianInner";
import { useState } from "react";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { MdScoreboard } from "react-icons/md";
import LiveMatchTracker from "../pages/Score/ScoreBoard";
const HomeInner = ({
  item,
  obj1,
  matchOdds,
  multiOddsPremium,
  setActiveFav,
  activeFav,
  score,
  showScore,
  setShowScore,index
}) => {
  const navigate = useNavigate();
  const [scrollHeight, setScrollHeight] = useState(50);
  const addToFavioute = async (
    id,
    favoriteMarketId,
    favoriteType,
    eventType
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteMarketId=${favoriteMarketId}&favoriteType=${favoriteType}&eventType=${eventType}`
    );
  };

  useEffect(() => {
    let header = document.getElementById("header")
      ? Number(document.getElementById("header")?.offsetHeight)
      : 0;
    setScrollHeight(header);
  }, []);
  let scoreCheck =
    (score?.length > 0 &&
      !isEmpty(
        score?.find((res) => {
          return Number(res?.eventId) == Number(item?.eventId);
        })
      )) ||
    (item?.outcome && item?.outcome != undefined);

  return (
    <div class="market-data">
      <div
        onClick={() =>
          navigate(`/full-market/${item?.marketId}/${item?.eventId}`)
        }
        className="d-flex"
      >
        {item.status == "in_play" ? (
          <div class="date-time in-play">
            <div class="animate">
              <ul class="flip-animation">
                <li class="time_date">
                  <span class="time">Today</span>
                  <span class="date">
                    {" "}
                    {obj1.length > 2
                      ? obj1[1] + " " + obj1[2]
                      : obj1[0] + " " + obj1[1]}
                  </span>
                </li>
                <li _ngcontent-cgj-c85="">
                  <span class="in-play-light">
                    <div class="icon-holder-small">
                      <div class="sports-icon inplay-light-icon"></div>
                    </div>
                    In-Play
                  </span>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div class="date-time">
            <div>
              <ul>
                <li class="time_date">
                  <span class="time">
                    {obj1.length > 2 ? obj1[0] : "Today"}
                  </span>
                  <span class="date">
                    {" "}
                    {obj1.length > 2
                      ? obj1[1] + " " + obj1[2]
                      : obj1[0] + " " + obj1[1]}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}
        <a href="#_">
          <div class="game-name">
            <div class="versis-img">
              {" "}
              {item?.eventName?.split(" v ")[0]}
              <span class="vs-img notranslate">
                <svg width="18" height="18" viewBox="0 0 65.141 65.34">
                  <defs>
                    <clipPath id="clip-path">
                      <rect width="65.141" height="65.34" fill="none"></rect>
                    </clipPath>
                  </defs>
                  <g
                    id="Repeat_Grid_1"
                    data-name="Repeat Grid 1"
                    clip-path="url(#clip-path)"
                  >
                    <g
                      id="Group_3"
                      data-name="Group 3"
                      transform="translate(-757.532 -436.426)"
                    >
                      <path
                        id="Subtraction_2"
                        data-name="Subtraction 2"
                        d="M27,54A27.007,27.007,0,0,1,16.49,2.122,27.007,27.007,0,0,1,37.51,51.878,26.832,26.832,0,0,1,27,54Zm5.451-22.123L28.6,32.7a6.748,6.748,0,0,0,3.262,4.27,11.626,11.626,0,0,0,5.729,1.37,10.188,10.188,0,0,0,6.324-1.861,6.086,6.086,0,0,0,2.442-5.1A6.335,6.335,0,0,0,45.2,27.7c-.77-1.106-2.3-1.955-4.56-2.522L36.9,24.24c-2.031-.5-3.061-1.392-3.061-2.643a2.414,2.414,0,0,1,.975-1.966,4.488,4.488,0,0,1,2.828-.773c2.117,0,3.483.84,4.061,2.5l3.77-.935a5.612,5.612,0,0,0-2.844-3.513,10.3,10.3,0,0,0-5.068-1.24,8.643,8.643,0,0,0-5.607,1.78,5.813,5.813,0,0,0-2.191,4.73,6.448,6.448,0,0,0,.62,2.787,5.048,5.048,0,0,0,1.651,2.022,10.4,10.4,0,0,0,3.191,1.281l4.206,1.1a3.722,3.722,0,0,1,2.208,1.2,2.938,2.938,0,0,1,.516,1.668,2.512,2.512,0,0,1-1.136,2.078,5.426,5.426,0,0,1-3.247.838,6.961,6.961,0,0,1-3.641-.818A3.639,3.639,0,0,1,32.451,31.877ZM9.065,16.006,15.848,38h4.028l6.881-21.994h-3.3L18.3,32.86,13.254,16.006Z"
                        transform="translate(763 442)"
                      ></path>
                      <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                        transform="translate(1580.574 938.646) rotate(180)"
                      ></path>
                      <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M768.4,489.5l2.639,2.463-9.473,9.438h0s-1.527,1.734-3.042.172a1.77,1.77,0,0,1,.2-2.8Z"
                        transform="translate(-0.369 -0.455)"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>{" "}
              {item?.eventName?.split(" v ")[1]}
            </div>
            <div class="game-list-time">
              <span class="game-subtitle">{item?.seriesName}</span>
            </div>
          </div>
        </a>
      </div>
      <div
        class={
          scoreCheck
            ? "market-count mb-0 mt-2 d-flex justify-content-between align-items-center"
            : "market-count mb-0 mt-2"
        }
      >
        {scoreCheck ? (
          <div className="counter">
            {
              score?.find((res) => {
                return Number(res?.eventId) == Number(item?.eventId);
              })?.score
            }
          </div>
        ) : item?.outcome && item?.outcome != undefined ? (
          <div className="counter">{item?.outcome}</div>
        ) : (
          ""
        )}
        <div class="right badge-right">
          <MdScoreboard
            className="m-0"
            color={showScore?.includes(item?.scoreId) ? "#c69700" : "#b8b8b8"}
            size={18}
            onClick={(e) => {
              setShowScore(item?.scoreId);
            }}
          />

          {item?.channel !== "false" && (
            <a href="#_">
              <FaTv />
            </a>
          )}
          {item?.hasMatchOdds && (
            <a>
              MO
              <span class="badge">{item?.marketCount || 0}</span>
            </a>
          )}

          {item?.hasFancy && (
            <a>
              F
              <span class="badge">
                {item?.fancyCount || Math.floor(Math.random() * 11)}
              </span>
            </a>
          )}
          {item?.hasBookmaker && (
            <a>
              B<span class="badge">{2}</span>
            </a>
          )}
        </div>
      </div>
      <SportAccordianInner
        matchOdds={matchOdds}
        setActiveFav={setActiveFav}
        activeFav={activeFav}
        addToFavioute={addToFavioute}
        setShowScore={setShowScore}
        scrollHeight={scrollHeight}
        multiOddsPremium={multiOddsPremium}
        object={item}
        score={score}
        index={index}
      />

      {showScore?.includes(item?.scoreId) && (
        <LiveMatchTracker
          scoreId={item?.scoreId}
          st={true}
          game={item?.gameType}
        />
      )}
    </div>
  );
};

export default HomeInner;
